import React from 'react';
import {
  Col, Menu, Row, Typography,
} from 'antd';
import { AiFillSetting, AiOutlineAppstore, AiOutlineMail } from 'react-icons/ai';
import { BaseLayout } from '../../components/layout/base/base-layout';

const { SubMenu } = Menu;

const text1 = `
  Akommo.com is an online management platform offering event-time accommodation and services for delegates and groups attending corporate congresses and trade shows throughout Europe. Akommo.com also provides private venue hire in many European cities. 
`;

const text2 = `
Signing up to Akommo.com allows you to create wishlists of event accommodation, as well managing your bookings. Best of all, signing up will give you access to one of our accommodation and venue hire experts, who will support you along the way. 
`;
const {
  Paragraph,
  Title,
} = Typography;

const FAQPage = () => {
  const renderFaq = () => {
    const currentText = text2;
  };
  return (
    <BaseLayout>
      <Row gutter={[32, 32]}>
        <Col>
          <Menu
            onClick={renderFaq}
            style={{ width: 256 }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
          >
            <SubMenu
              key="sub1"
              title={(
                <span>
                  <AiOutlineMail />
                  <span>How do I sign up?</span>
                </span>
              )}
            >
              <Menu.ItemGroup key="g1" title="Step 1">
                <Menu.Item key="1">{text2}</Menu.Item>
                <Menu.Item key="2">Option 2</Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup key="g2" title="Item 2">
                <Menu.Item key="3">Option 3</Menu.Item>
                <Menu.Item key="4">Option 4</Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
            <SubMenu key="sub2" icon={<AiOutlineAppstore />} title="Navigation Two">
              <Menu.Item key="5">Option 5</Menu.Item>
              <Menu.Item key="6">Option 6</Menu.Item>
              <SubMenu key="sub3" title="Submenu">
                <Menu.Item key="7">Option 7</Menu.Item>
                <Menu.Item key="8">Option 8</Menu.Item>
              </SubMenu>
            </SubMenu>
            <SubMenu
              key="sub4"
              title={(
                <span>
                  <AiFillSetting />
                  <span>Navigation Three</span>
                </span>
              )}
            >
              <Menu.Item key="9">Option 9</Menu.Item>
              <Menu.Item key="10">Option 10</Menu.Item>
              <Menu.Item key="11">Option 11</Menu.Item>
              <Menu.Item key="12">Option 12</Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
        <Col>
          <Title level={2}>
            What if I have questions?
          </Title>
          <Paragraph>
            Render text here once something is clicked
          </Paragraph>
        </Col>
      </Row>

    </BaseLayout>
  );
};

export default FAQPage;
